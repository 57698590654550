import React from "react";
import styled from "@emotion/styled"

import Section from "@narative/gatsby-theme-novela/src/components/Section";
import SEO from "@narative/gatsby-theme-novela/src/components/SEO";
import Layout from "@narative/gatsby-theme-novela/src/components/Layout";
import { Template } from "@narative/gatsby-theme-novela/src/types";
import mediaqueries from "@narative/gatsby-theme-novela/src/styles/media";

import Body from '../components/Body'

const Header = styled.h2`
    font-size: 2em;
`

const MainDiv = styled(Body)`
  ${mediaqueries.desktop_medium_up`
    font-size: 1.33em;
  `}

  ${mediaqueries.tablet`
    font-size: 1em;
  `}

  ${mediaqueries.phablet`
    font-size: 1em;
  `}
`

const Div = ({header, text, children}) => {
  return (<div style={{margin: '20px 0px'}}>
    {header ? <h2>{header}</h2> : null}
    {children}
  </div>)
}

const ContactPage: Template = ({ location, pageContext }) => {
  return (
    <Layout>
      <SEO pathname={location.pathname} />
      <Section>
        <MainDiv>
          <Header>Contact me</Header>
          <Div>
              Best way to contact me is to write me an email: <a href="mailto:daniil.ganiev@gmail.com">daniil.ganiev@gmail.com</a>
          </Div>
        </MainDiv>
      </Section>
    </Layout>
  );
};

export default ContactPage;


